/* Fonts */

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Light.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Regular.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Medium.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Bold.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* Commons */

:root {
  /* Set the background of the entire app */
  --ion-background-color: #fafcff;
  --ion-font-family: "Poppins";
  --ion-text-color: var(--ion-color-primary);
}

ion-header {
  text-align: left;

  ion-title {
    text-align: left;
    font-size: 1.5625rem;
  }
}

.cbo-container {
  padding: var(--meta-padding-main);
  min-height: 100%;

  &.container--white {
    background: white;
  }
}

/* Forms */

.cbo-form {
  .cbo-input {
    width: 100%;
    padding: 0.75rem 1.875rem;
    color: var(--ion-color-secondary);
    border-radius: 0.3125rem;
    border: 2px solid var(--ion-color-light);
    background: white;
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    outline: none;
    transition:border .4s;

    &::placeholder {
      color: var(--ion-color-light-shade);
    }

    &.input--uppercase {
      text-transform: uppercase;
    }

    &.cbo-errorfield {
      border-color: var(--ion-color-danger);
    }
  }

  ion-textarea,
  ion-input,
  ion-datetime,
  ion-select {
    --padding-start: 1.875rem !important;
    --padding-end: 1.875rem !important;
    --padding-top: 0.75rem !important;
    --padding-bottom: 0.75rem !important;
    --color: var(--ion-color-secondary) !important;
    --placeholder-color: var(--ion-color-light-shade) !important;
    --background: #ffffff !important;
    border: 2px solid var(--ion-color-light);
    border-radius: 0.3125rem;
    text-align: left;
    font-size: 1rem !important;
    font-weight: 500;
    transition:border .4s;

    &.cbo-errorfield {
      border-color: var(--ion-color-danger);
    }
  }

  ion-select{
    background: #ffffff;
    color: var(--ion-color-secondary);
    min-height: 3.3125rem;
  }

  ion-textarea {
    --padding-start: 1.5rem !important;
    --padding-end: 1.5rem !important;
    --padding-top: 1.5rem !important;
    --padding-bottom: 1.5rem !important;
    border-radius: 0.3125rem;
  }

  ion-label {
    color: var(--ion-color-medium-tint) !important;
    font-weight: 300;
  }

  ion-list {
    ion-item {
      --padding-start: 0px;
      --inner-padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0px;

      /*
      See commented part below

      &.item-label-floating{
        --min-height:50px;
      }
      */
    }

    /*
    Floating labels given up because of browser autofill
    See : https://bugs.chromium.org/p/chromium/issues/detail?id=468153#c164
    And : https://github.com/ionic-team/ionic-framework/issues/17391

    ion-label.label-floating{
      font-size:1rem !important;
      font-weight:500;
      margin:0 !important;
      position: absolute;
      height:auto;
      z-index:3;
      left:1.8750rem;
      top:-1rem;
      color:var(--ion-color-light-shade) !important;
    }

    .item-has-focus,
    .item-has-value{
      ion-label.label-floating{
        transform: translate3d(0, 1.25rem, 0) scale(0.7) !important;
      }
    }
    */
  }
}

.cbo-formerror {
  color: var(--ion-color-danger);
  font-size: 14px;
  font-style: italic;
  width: 100%;
  margin: 5px 0 0 0;
}

.cbo-formlegend {
  color: #b4b5b3;
  font-size: 14px;
  width: 100%;
  margin: 5px 0 0 0;
}

/* Buttons */

.cbo-button {
  --border-radius: 0.3125rem;
  --border-width: 1px;
  --padding-top: 0.25rem;
  --padding-bottom: 0.25rem;
  --box-shadow: none;
  --opacity: 1;
  margin: 0;
  font-weight: bold;
  font-size: 1rem;
  transition: all .5s;

  &.button-disabled {
    --opacity: .25;
  }

  &.button--border {
    --border-width: 1px;
    --border-color: var(--ion-color-primary);
    --border-style: solid;
    --background: transparent;
    --color: var(--ion-color-primary);
  }
}

.cbo-link {
  --border-width: 0;
  --background: none;
  --padding-start: 0;
  --padding-end: 0;
  --border-radius: 0;
  --background-activated: #f6f6f6 !important;
  --background-focused: #f6f6f6 !important;
  color: var(--ion-color-primary);
  font-weight: bold;
  text-transform: none;
  text-decoration: underline;
  height: auto;

  &.link--small {
    font-weight: 500;
    font-size: 0.8125rem;
  }
}

.cbo-sectionlink {
  --border-width: 0;
  --background: none;
  --padding-start: 0;
  --padding-end: 0;
  --border-radius: 0;
  --background-activated: none;
  --background-focused: none;
  font-size: 0.8125rem;
  font-weight: 400;
  color: var(--ion-color-primary);
  text-transform: uppercase;
  opacity: 1;

  ion-icon {
    margin-right: 0.5rem;
    font-size: 0.875rem;
  }
}

/* Typography */

h1 {
  font-size: 1.5625rem;
  font-weight: 500;
  color: var(--ion-color-secondary);
}

h2 {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--ion-color-primary);
}

h3 {
  font-size: 1rem;
  font-weight: 700;
  color: var(--ion-color-secondary);
}

.cbo-cms {
  p,
  ul,
  ol {
    font-size: 0.8125rem;
    color: var(--ion-color-secondary);
  }
}

.cbo-subtitle {
  color: var(--ion-color-secondary);
  font-weight: 300;
}

.cbo-sectiontitle {
  font-size: 0.8125rem;
  font-weight: 300;
  color: var(--ion-color-dark);
  text-transform: uppercase;
  opacity: 0.4;
}

.cbo-notfound{
  display: flex;
  align-items:center;
  justify-content: center;

  .notfound-text{
    font-weight:500;
    text-align:center;
  }
}

.cbo-loader{
  .loading-content.sc-ion-loading-ios{
    font-weight:500;
  }
}

.cbo-toaster{
  font-size:0.75rem;
  line-height: 1.2em;
  font-weight:500;
  text-align: center;
}