.cbo-part {
  margin-top: 0.625rem;

  .part-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.3125rem;
    background: var(--meta-color-main);
    padding: 0.625rem 1.25rem;

    .box-content {
      .content-name {
        font-size: 0.8125rem;
        font-weight: 300;
        color: var(--ion-color-dark);
        opacity: 0.5;
      }

      .content-description {
        font-size: 1rem;
        font-weight: 500;
        color: var(--ion-color-secondary);
      }
    }
  }

  .part-infos {
    margin-top: 0.625rem;

    p {
      margin: 0;
    }
  }
}
