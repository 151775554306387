.cbo-search {
  .search-title{
    margin-top:-1.25rem;
  }

  .search-tabs {
    display: flex;
    margin-top:2.3125rem;
    margin-bottom:2.3125rem;

    ion-button{
      margin-left: 1.2500rem;

      &:first-child{
        margin-left:0;
      }
    }
  }

  .search-registration{
    .registration-submit{
      text-align: right;
    }
  }

  .search-model{
    h3{
      margin-bottom:2.3125rem;
    }
  }

  .search-fallback{
    margin-bottom: 6rem;
  }
}
