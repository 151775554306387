.cbo-sheet {
  .sheet-header {
    background: var(--meta-color-main);
    padding: 1rem var(--meta-padding-main);
    margin: var(--meta-padding-reverse);
    margin-bottom: 0;
    position: relative;

    .header-energy {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 1.25rem;
      right: var(--meta-padding-main);
      white-space: nowrap;
      height: 1.25rem;
      padding: 0 0.5rem;
      background: var(--ion-color-primary);
      color: white;
      font-size: 0.625rem;
      border-radius: 0.3125rem;
    }

    .header-title {
      margin-top: 0;
      text-transform: capitalize;
    }

    .header-infos {
      margin: 0.75rem 0;

      p {
        margin: 0;
        color: var(--ion-color-medium-tint);
        font-weight: 300;

        strong {
          display: inline-block;
          margin-right: 0.35rem;
          color: var(--ion-color-secondary);
          font-weight: 500;
        }
      }
    }

    .header-tabs {
      ion-button {
        margin-left: 1.25rem;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .header-difficulty {
      margin-top: 0.75rem;
    }
  }

  .sheet-introduction {
    figure {
      margin: 1rem 0;
    }
  }

  .sheet-pictures {
    padding-top: 0.5625rem;

    .cbo-picture {
      margin-top: 0.5625rem;

      .picture-wrapper {
        padding: 0;

        img,
        ion-img {
          position: relative;
          height: auto;
          object-fit: initial;
        }
      }
    }
  }

  .video {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

}