.cbo-invoicebtn {
  --padding-start: 0;
  --padding-end: 0;
  --opacity: 1;
  margin: 0;
  width: 4.375rem;
  height: 4.375rem;
  font-size: 2.0625rem;
  position: absolute;
  bottom: var(--meta-padding-main);
  right: var(--meta-padding-main);

  ion-icon {
    font-size: 1em;
    margin: -0.15em -0.15em 0 0;
  }
}
