.cbo-autocomplete {
  .autocomplete-suggestions {
    .suggestions-el {
      margin-top: 0.625rem;

      ion-button{
        text-transform: capitalize;
      }
    }
  }
}
