/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** custom colors **/
  --meta-color-main: #fafcff;
  --meta-padding-main: 1.875rem;
  --meta-padding-reverse: -1.875rem;

  /** primary **/
  --ion-color-primary: #204e96;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #204e96;
  --ion-color-primary-tint: #204e96;

  /** secondary **/
  --ion-color-secondary: #10274a;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #10274a;
  --ion-color-secondary-tint: #10274a;

  /** tertiary **/
  --ion-color-tertiary: #ffffff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #10274a;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #ffffff;
  --ion-color-tertiary-tint: #ffffff;

  /** success **/
  --ion-color-success: #169759;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #169759;
  --ion-color-success-tint: #169759;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #b11d38;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #b11d38;
  --ion-color-danger-tint: #b11d38;

  /** dark **/
  --ion-color-dark: #353d4a;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #d3dae5;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #353d4a;
  --ion-color-dark-tint: #353d4a;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #929fb2;

  /** light **/
  --ion-color-light: #edf4ff;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d1d5de;
  --ion-color-light-tint: #EDEFF2;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
  }

  .ios ion-modal {
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
  }
}
