.cbo-logout {
  --max-width: 19.6875rem;
  --max-height: 14.375rem;
  --border-radius: 0.375rem;

  .logout-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;

    h3,
    p {
      margin: 0;
    }
  }

  .logout-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 0.5rem;
  }
}
