.cbo-difficulty {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  font-weight: 500;
  color: var(--ion-color-success);

  &:before {
    content: "";
    display: inline-block;
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    background: var(--ion-color-success);
    margin-right: 0.25rem;
  }

  p {
    margin: 0;
  }

  &.difficulty--2 {
    color: var(--ion-color-warning);

    &:before {
      background: var(--ion-color-warning);
    }
  }

  &.difficulty--3 {
    color: var(--ion-color-danger);

    &:before {
      background: var(--ion-color-danger);
    }
  }

  &.difficulty--4 {
    color: black;

    &:before {
      background: black;
    }
  }
}
