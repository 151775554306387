.cbo-order {
  .order-title {
    margin-top: 0;
    margin-bottom: 1.25rem;
  }

  .order-noresult {
    margin: 2.5rem 0;
    font-weight: 300;
    text-align: center;
    color: var(--ion-color-secondary);
  }
}
