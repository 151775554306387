.cbo-login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

.cbo-forgotpassword {
  margin-top: 7rem;

  ion-content{
    --padding-top:1rem;
  }

  .forgotpassword-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    height: 100%;
    box-shadow: 0 0.25rem 1rem rgba(51, 51, 51, 0.2);
  }

  .forgotpassword-close {
    --padding-start:0;
    --padding-end:0;
    --color:var(--ion-color-medium-tint);
    height:auto;
    position: absolute;
    top:2.5rem;
    right:2.1875rem;
    margin:0;
    font-size:0.6rem;
  }
}
