.cbo-infobubble {
  display: flex;
  align-items:flex-start;
  justify-content: space-between;
  background: var(--ion-color-light);
  padding: 0.625rem;

  ion-icon{
    font-size:1.25rem;
    min-width: 1.25rem;
  }

  ion-icon + .infobubble-text{
    padding-left:0.5rem;
  }

  .infobubble-text{
    p, ul, ol{
      margin:0;
      color:var(--ion-color-primary);
      font-size:0.8125rem;
      font-weight:300;
      line-height: 1.2308em;

      strong{
        font-weight: 500;
      }
    }
  }
}
