.cbo-picture {
  .picture-wrapper{
    position:relative;
    padding-bottom:49.21%;
    border-radius:0.3125rem;
    overflow: hidden;

    ion-img{
      position:absolute;
      width:100%;
      height:100%;
      object-fit: cover;
    }
  }

  .picture-legend{
    font-size:0.6250rem;
    font-weight:500;
    color:var(--ion-color-secondary);
    margin-top:0.5625rem;
  }
}
