.cbo-invoice {
  .invoice-header {
    background: white;
    padding: 1rem var(--meta-padding-main);
    margin: var(--meta-padding-reverse);
    margin-bottom: 0;
    position: relative;

    .header-title {
      margin-top: 0;
    }
  }

  .invoice-content {
    .content-area {
      margin: 1.25rem 0 2.5rem 0;

      .area-title {
        color:var(--ion-color-primary);
        opacity:1;
      }

      .area-trigger {
        ion-button {
          --border-width: 2px;
          --border-style: solid;
          --border-color: var(--ion-color-light);
          --padding-start: 1.875rem;
          --padding-end: 1.25rem;
          height: 3.125rem;

          .trigger-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            ion-icon {
              font-size: 1.25rem;
            }
          }
        }
      }

      .area-summary {
        background: white;
        border: 2px solid var(--ion-color-light);
        padding: 0.625rem 1.25rem;
        border-radius: 0.3125rem;
        margin-top: 0.25rem;

        p {
          margin: 0.25rem 0;
          line-height: 1.2308em;
          white-space: pre-line;
        }
      }
    }
  }

  .invoice-total {
    margin: 0 var(--meta-padding-reverse);

    .total-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: white;
      border-top: 1px solid var(--ion-color-light);
      padding: 0 var(--meta-padding-main);
      height: 4rem;

      &:last-child {
        border-bottom: 1px solid var(--ion-color-light);
      }

      ion-input {
        --padding-start: 0;
        --padding-end: 0;
        --color: var(--ion-color-medium-tint);
        --placeholder-color: var(--ion-color-medium-tint);
        --placeholder-opacity: 1;
        text-align: right;
      }

      .line-label {
        color: var(--ion-color-secondary);
        white-space: nowrap;
      }

      .line-value {
        color: var(--ion-color-medium-tint);
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    .total-spacer {
      border-top: 1px solid var(--ion-color-light);
      margin-bottom: 0.625rem;
    }
  }

  .invoice-edition {
    margin: 0.45rem 0;
  }
}
