.cbo-actionbtn {
  --padding-start: 0;
  --padding-end: 0;
  --background: var(--meta-color-main);
  --background-focused: var(--ion-color-light-shade);
  --background-activated: var(--ion-color-light-shade);
  --background-hover: var(--ion-color-light-shade);
  --box-shadow: 0 0.125rem 0.3125rem rgba(51, 51, 51, 0.1);
  --color: var(--ion-color-primary);
  --overflow: visible;
  width: 2.8125rem;
  height: 2.8125rem;
  font-size: 1.5rem;

  ion-icon {
    font-size: 1em;
  }

  ion-badge {
    --background: #74a1e3;
    --padding-top: 0.125rem;
    --padding-bottom: 0.125rem;
    --padding-start: 0.125rem;
    --padding-end: 0.125rem;
    min-width: 1rem;
    min-height: 1rem;
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
    font-weight: 500;
    font-size: 0.8125rem;
  }

  &.actionbtn--active{
    --background: var(--ion-color-primary);
    --color: white;
  }
}
