.cbo-home {
  background: #050c17;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
  }

  &:before {
    top: 0;
    padding-bottom: 151.2%;
    background: url(../assets/images/home-top.svg);
    background-size: 100% auto;
  }

  &:after {
    bottom: 0;
    padding-bottom: 135.73%;
    background: url(../assets/images/home-bottom.svg);
    background-size: 100% auto;
  }

  .home-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 19.6875rem;

    ion-img {
      width: 100%;
      height: auto;
    }
  }

  .home-button {
    position: relative;
    z-index: 1;
    width:100%;
  }
}
