.cbo-header {
  ion-toolbar {
    --background: var(--meta-color-main);
    --border-width: 0 !important;
    --padding-start: 1.875rem;
    --padding-end: 1.875rem;
    --padding-top: 0.75rem;
    --padding-bottom: 0.75rem;
  }

  ion-button {
    margin: 0;
  }

  .header-backwrap{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .header-back {
    --padding-start: 0;
    --padding-end: 0;
    --icon-font-size: 0.875rem;
    --background:none;
    --background-focused:none;
    --background-hover:none;
    --background-activated:none;
    --color:var(--ion-color-primary);
    width: 1rem;
    margin-right: 1.5rem;

    ion-icon{
      font-size:0.875rem;
    }
  }

  .header-buttons {
    display: flex;
  }

  &.header--white {
    ion-toolbar {
      --background: white;
    }
  }
}
