.cbo-footer {
  ion-toolbar {
    --background: var(--meta-color-main);
    --border-width: 0 !important;
    --padding-start: 1.875rem;
    --padding-end: 1.875rem;
    --padding-top: 0.5rem;
    --padding-bottom: 1.875rem;
  }

  &.footer--white {
    ion-toolbar {
      background: white;
    }
  }
}
