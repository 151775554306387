.cbo-orderpart {
  background: white;
  padding: 0.625rem;
  box-shadow: 0 0.125rem 0.3125rem rgba(51, 51, 51, 0.1);
  border-radius: 0.9375rem;
  margin-bottom: 1.25rem;

  .orderpart-header {
    .header-car {
      color: var(--ion-color-primary);
      font-size: 0.8125rem;
      font-weight: 300;
      text-transform: capitalize;
    }

    .header-part {
      .part-name {
        font-size: 0.8125rem;
        font-weight: 300;
        color: var(--ion-color-dark);
        opacity: 0.5;
      }

      .part-description {
        font-size: 1rem;
        font-weight: 500;
        color: var(--ion-color-primary);
      }
    }
  }

  .orderpart-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;

    .actions-quantity {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      ion-button {
        --background: white;
        --background-activated: #f6f6f6 !important;
        --background-focused: #f6f6f6 !important;
        --color: var(--ion-color-dark);
        --border-color: var(--ion-color-dark-contrast);
        --border-style: solid;
        --border-width: 1px;
        --border-radius: 0.625rem;
        --font-size: 1rem;
        --padding-top: 0;
        --padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;
        margin: 0;
        width: 1.875rem;
        height: 1.875rem;

        ion-icon {
          font-size: 1em;
        }
      }

      ion-input {
        --padding-top: 0;
        --padding-bottom: 0;
        width: 3.5rem;
        hieght: 1.875rem;
        text-align: center;
      }
    }

    .actions-delete {
      ion-button {
        --background-activated: #f6f6f6 !important;
        --background-focused: #f6f6f6 !important;
        --padding-top: 0;
        --padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;
        --font-size: 1.5rem;
        margin: 0;
        width: 1.875rem;
        height: 1.875rem;

        ion-icon {
          font-size: 1.5rem;
        }
      }
    }
  }
}
