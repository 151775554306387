.cbo-searchfooter {
  ion-toolbar {
    --background: var(--meta-color-main);
    --border-width: 0 !important;
    --padding-start: 1.875rem;
    --padding-end: 1.875rem;
    --padding-top: 0.5rem;
    --padding-bottom: 1.875rem;
    --min-height: 4.4375rem;
  }

  .searchfooter-top{
    width:100%;
    margin-bottom: 1rem;
  }

  .searchfooter-bottom{
    .bottom-back {
      --color: var(--ion-color-secondary);
      font-weight: 500;
      text-decoration: none;
      margin: 0;
      position: relative;
      z-index: 1;

      ion-icon {
        font-size: 0.875rem;
        margin-right: 0.75rem;
      }
    }

    .bottom-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 2rem;

      .dots-el {
        display: block;
        width: 0.6875rem;
        height: 0.6875rem;
        border-radius: 50%;
        background: var(--ion-color-light);
        margin: 0 0.1562rem;
        transition: all 0.4s;

        &.el--active {
          background: var(--ion-color-primary);
        }
      }
    }
  }
}
